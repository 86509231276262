import React, {FC} from 'react';
import {Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
/*import Login from "./pages/Login";
import Price from "./pages/Price";
import Registration from "./pages/Registration";
import Congratulation from "./pages/Congratulation";
import Video from "./pages/Video";*/
const App:FC = () => {
  return (
      <>
          <Routes>
              <Route path="/" element={ <Home />}/>
{/*              <Route path="/registration" element={<Registration />} />
              <Route path="/congratulation" element={<Congratulation/>} />
              <Route path="/video" element={<Video/>} />
              <Route path="/login" element={<Login/>} />
              <Route path="/price" element={<Price/>} />*/}
              <Route path="*" element={<Home/>} />
          </Routes>
      </>
  );
};

export default App;
