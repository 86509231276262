import React, {FC, useEffect, useState} from 'react';
import List from "../UI/List";
import ReactIcon from "../UI/ReactIcon";
import PaymentsIcon from "../Icons/takes/PaymentsIcon";
import BookingIcon from "../Icons/takes/BookingIcon";
import OrdersIcon from "../Icons/takes/OrdersIcon";
import {useOnDisplay} from "../../hooks/useOnDisplay";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const TakeSection:FC = () => {
    const {isOnDisplay, ref} = useOnDisplay();
    const [isShowed,setIsShowed] = useState(false);

    let timeline = gsap.timeline({repeat:0});
    useEffect(()=>{
        if(isOnDisplay && !isShowed){
            setIsShowed(true)
            const UpStart = {y:50,opacity:0,duration: 0.5, stagger: 0.5};
            const InFinish = {y:0,opacity:1,duration: 0.5, stagger: 0.5};

            timeline.fromTo(".take-section-item", UpStart, InFinish, 0.5);
        }
    },[isOnDisplay])
    return (
        <section className={'take-section'} ref={ref}>
            <div className="container">
                <div className="take-section__wrap">
                    <div className="take-section-item hidden">
                        <div className="take-section-item__img">
                            <ReactIcon>
                                <OrdersIcon/>
                            </ReactIcon>
                        </div>
                        <div className="take-section-item__content">
                            <div className="take-section-item__header">TAKE ONLINE <span>ORDERS</span> Easy</div>
                            <div className="take-section-item__subtitle">Start accepting and manage all types of online orders</div>
                            <div className="take-section-item__description">
                                <List className={'list-orange take-section-item__list'} items={['Dine in', 'Preorder', 'Take away', 'Delivery']}/>
                            </div>
                        </div>
                    </div>
                    <div className="take-section-item hidden">
                        <div className="take-section-item__img">
                            <ReactIcon type={'blue'}>
                                <BookingIcon/>
                            </ReactIcon>
                        </div>
                        <div className="take-section-item__content">
                            <div className="take-section-item__header">TAKE ONLINE <span>BOOKINGS</span></div>
                            <div className="take-section-item__subtitle">Start accepting and manage online bookings</div>
                            <div className="take-section-item__description">
                                Smart booking system with online deposit
                            </div>
                        </div>
                    </div>
                    <div className="take-section-item hidden">
                        <div className="take-section-item__img">
                            <ReactIcon>
                                <PaymentsIcon/>
                            </ReactIcon>
                        </div>
                        <div className="take-section-item__content">
                            <div className="take-section-item__header">take online <span>PAYMENTS</span> and <span>TIPS</span></div>
                            <div className="take-section-item__subtitle">Online payments and online tips in any way convenient for you</div>
                            <div className="take-section-item__description">
                                Ideal replacement for bank terminal
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TakeSection;