import React, {FC} from 'react';
import ChangerLang from "./ChangerLang";
import {Link as LinkScroll} from "react-scroll";
import {Link} from "react-router-dom";

export interface IMenuItem {
    name: string | JSX.Element;
    link?: string;
    linkType: "link" | "a";
    type?: "drop"
    onClick?: (e:React.MouseEvent<HTMLAnchorElement>)=>void;
}

interface IMenu {
    className?: string;
    classNameMenu?: string;
    items: IMenuItem[];
    open: boolean;
    setOpen: (open:boolean)=>void;
}


const Menu:FC<IMenu> = ({items,className,classNameMenu, open, setOpen}) => {
    return (
        <nav className={className}>
            <ul className={`menu ${open ? "active" : ""} ${classNameMenu ? classNameMenu : ""}`}>
                {items.length > 0 && items.map((item,index)=>
                    <li key={index} className={'menu__item'}>
                        {item.linkType === "link" ?   <Link className={'menu__link'} onClick={item.onClick} to={item.link ? item.link : ''}>{item.name}</Link> :   <a className={'menu__link'} onClick={item.onClick} href={item.link ? item.link : ''}>{item.name}</a> }

                    </li>
                )}
                <li className={'menu__item menu__dropdown menu__lang-wrap'}>
                    <ChangerLang linkClass={'menu__link'} listClass={'menu__submenu menu__lang'}/>
                </li>
            </ul>
            <div className={`menu__burger ${open ? "active" : ""}`} onClick={()=>setOpen(!open)}>
                <span/>
                <span/>
                <span/>
            </div>
        </nav>
    );
};

export default Menu;