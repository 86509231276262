import React, {FC, useEffect, useState} from 'react';
import Button from "../UI/Button";
import { Trans, useTranslation} from "react-i18next";
import {useOnDisplay} from "../../hooks/useOnDisplay";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AdvertSection:FC = () => {
    const { t } = useTranslation();

    const {isOnDisplay, ref} = useOnDisplay();
    const [isShowed,setIsShowed] = useState(false);

    let timeline = gsap.timeline({repeat:0});
    useEffect(()=>{
        if(isOnDisplay && !isShowed){
            setIsShowed(true)
            const UpStart = {y:50,opacity:0,duration: 0.5, stagger: 0.5};
            const InFinish = {y:0,opacity:1,duration: 0.5, stagger: 0.5};

            timeline.fromTo(".advert__header", UpStart, InFinish, 0.5)
          //  timeline.fromTo(".advert__text", UpStart, InFinish, 2.0)
            timeline.fromTo(".advert__content", UpStart, InFinish, 0.9)
            timeline.fromTo(".advert__button", UpStart, InFinish, 1.3)
            ;
        }
    },[isOnDisplay]);

    return (
        <section className={'advert'} ref={ref}>
            <div className="advert__wrap">
                <h2 className="advert__header h2 hidden">
                    <Trans i18nKey="advert.header"/>
                </h2>
                <div className="advert__content hidden">
                    <Trans className={'advert__text'} i18nKey="advert.content"/>
                    <div className="advert__button hidden">
                        <Button className={'advert__btn '} type={'htmlLink'} href={'https://my.quicktouchmenu.com/'} name={t('advert.button')}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AdvertSection;