import React, {FC, useEffect, useRef, useState} from 'react';
import Header from "../../components/Header";
import {
    changeModalContact,
    changeModalFeedback,
    changeModalSuccess,
} from "../../redux/slices/modalsSlice";
import ContactsModal from "../../components/Modals/ContactsModal";
import FeedbackModal from "../../components/Modals/FeedbackModal";

import Footer from "../../components/Footer";
//import WOW from "wowjs";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";

import SmallModal from "../../components/Modals/SmallModal";
import Scrollbar from '../../Scrollbar';
// @ts-ignore
import AnimatedCursor from "react-animated-cursor"
//import { LocomotiveScrollProvider } from 'react-locomotive-scroll';

interface ILayout {
    headerType?: "relative" | "absolute"
    children?: React.ReactNode;
}

const Layout: FC<ILayout> = ({headerType, children}) => {
    const containerRef = useRef(null)
    /*    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
        ScrollSmoother.create({
            smooth: 1,               // how long (in seconds) it takes to "catch up" to the native scroll position
            effects: true,           // looks for data-speed and data-lag attributes on elements
            smoothTouch: 0.1,
        });*/

    //const [feedBtn, setFeedBtn] = useState(false);
    const [prevScroll, setPrevScroll] = useState(0);
    const [header, setHeader] = useState(false);
    const [outHeader, setOutHeader] = useState(false);
    const timelineEx = useSelectorEx(state => state.modals.timeline);
    useEffect(() => {

        if (timelineEx > 152 && timelineEx > prevScroll) {
            setOutHeader(true);
        } else {
            setOutHeader(false);
        }

        setHeader(timelineEx > 52);
        setPrevScroll(timelineEx);
    },[timelineEx]);
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        window.addEventListener("scroll", () => {
            /*
            setFeedBtn(window.scrollY < window.document.body.offsetHeight - window.innerHeight - 100);
            if (window.scrollY > 152 && window.scrollY > prevScroll) {
                setOutHeader(true);
            } else {
                setOutHeader(false);
            }

            setHeader(window.scrollY > 52);
            prevScroll = window.scrollY;*/



        });
    }, []);


    const {modalFeedback, modalContact, modalSuccess, modalErrorMessage} = useSelectorEx(state => state.modals);
    const dispatch = useDispatchEx();
    return (
        <>
            <AnimatedCursor
                innerSize={8}
                outerSize={40}
                color='255, 113, 22'
                outerAlpha={0.2}
                innerScale={0.7}
                outerScale={3}

                clickables={[
                    'a',
                    'input[type="text"]',
                    'input[type="email"]',
                    'input[type="number"]',
                    'input[type="submit"]',
                    'input[type="image"]',
                    'label[for]',
                    'select',
                    'textarea',
                    'button'
                ]}

            />
            <Header className={`${headerType === 'relative' ? "header-" + headerType : ""}`} attach={header} out={outHeader}/>
            <Scrollbar>
                <main ref={containerRef}>
                    {children}
                </main>
                <Footer/>
            </Scrollbar>
            <ContactsModal active={modalContact} onClose={() => dispatch(changeModalContact())}/>
            <FeedbackModal active={modalFeedback} onClose={() => dispatch(changeModalFeedback())}/>
            {/*            <RegistrationModal active={modalRegistration} onClose={()=>dispatch(changeModalRegistration())} />
            <LoginModal active={modalLogin} onClose={()=>dispatch(changeModalLogin())}/>*/}
            {/*            <VideoModal active={modalVideo} onClose={()=>dispatch(changeModalVideo())} />*/}
            {/*            */}
            <SmallModal active={modalSuccess} onClose={() => dispatch(changeModalSuccess())} header={'Thanks!'} content={'Our manager will contact you soon!'}/>
            <SmallModal active={modalErrorMessage} onClose={() => dispatch(changeModalSuccess())} header={'Error!'} content={'Can`t sent message!'}/>


        </>
    );
};

export default Layout;