import React, {FC} from 'react';
import PlateItem, {IPlateItem} from "./PlateItem";

interface IPlate {
    className?:string;
    items?:IPlateItem[]
}

const Plate:FC<IPlate> = ({className,items=[]}) => {
    return (
        <div className={`plate ${className ? className: ''}`}>
            {
                items.length > 0 ?
                items.map((item,index)=><PlateItem className={item.className} key={index} header={item.header} count={item.count} desc={item.desc}/>)
                : "Items not found"
            }
        </div>
    );
};

export default Plate;