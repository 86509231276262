import React, {FC, useState} from 'react';
import i18next from "i18next";

interface ILang {
    name:string;
    lng: string;
}

interface IChangerLang {
    linkClass: string;
    listClass: string;
}

const ChangerLang:FC<IChangerLang> = ({linkClass, listClass}) => {
    const lang = [
        {name:"En",lng:'en'},//english
       // {name:"RU",lng:'ru'},//
        {name:"De",lng:'de'},//german
        {name:"Lt",lng:'lt'},//litva
        {name:"Lv",lng:'lv'},//latvia
        {name:"Et",lng:'et'}//estonia
    ];
    const loadLang = localStorage.getItem('qtm-lang') ? localStorage.getItem('qtm-lang') : 'en';
    const currentLang = lang.filter(item=>item.lng === loadLang)[0];
    const [active,setActive] = useState(currentLang?.name);
    const changeLanguage = (item:ILang)=>{
        setActive(item.name);
        localStorage.setItem('qtm-lang',item.lng);
        i18next.changeLanguage(item.lng)
    };
    return (
        <>
            <a className={linkClass}>{active}</a>
            <ul className={listClass}>
                {lang.filter(item=>item.name !== active).map((item,index)=><li key={index}><a onClick={()=>changeLanguage(item)} >{item.name}</a></li>)}
            </ul>
        </>
    );
};

export default ChangerLang;