import React from 'react';

const PlusIcon = () => {
    return (
        <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="23.5" y1="1.75879" x2="23.5" y2="45.7588" stroke="#333333" strokeWidth="3" strokeLinecap="round"/>
            <line x1="1.5" y1="23.7588" x2="45.5" y2="23.7588" stroke="#333333" strokeWidth="3" strokeLinecap="round"/>
        </svg>
    );
};

export default PlusIcon;