import React, {Component} from 'react';
import MainSection from "../components/sections/MainSection";
import OnlineSection from "../components/sections/OnlineSection";
import AdvertSection from "../components/sections/AdvertSection";
import QuestionsSection from "../components/sections/QuestionsSection";
import Layout from "./layouts/Layout";
import ScrollUpButton from "../components/Buttons/ScrollUpButton";
import IndependentSection from "../components/sections/IndependentSection";
import ReducingSection from "../components/sections/ReducingSection";
import ThinkingSection from "../components/sections/ThinkingSection";
import NoCostSection from "../components/sections/NoCostSection";
import DemoSection from "../components/sections/DemoSection";
import TakeSection from "../components/sections/TakeSection";
import LikesSection from "../components/sections/likes-section";
import TipsSection from "../components/sections/TipsSection";

type MyProps = {  };
type MyState = { scrollUp: boolean };
class Home extends Component<MyProps, MyState> {
    constructor(props:any) {
        super(props);
        this.state = {
            scrollUp: false,
        }
    }
    componentDidMount(): void {
        window.addEventListener("scroll", () => {
            this.setState({scrollUp: window.scrollY > 150});
        });
        if(window.location.hash) {
            setTimeout(()=> {
                document.getElementById(window.location.hash.split('#')[1])?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            },1000);
        }
    }

    scrollUpHandle = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    };
    render() {
        return (
            <Layout>
                <MainSection/>
                <OnlineSection/>
                <IndependentSection/>
                <TakeSection/>
                <LikesSection/>
                <TipsSection/>
                <ReducingSection/>
                <ThinkingSection/>
                <NoCostSection/>
                <DemoSection/>
                <AdvertSection/>
                <QuestionsSection/>
                <ScrollUpButton active={this.state.scrollUp} onClick={this.scrollUpHandle}/>
            </Layout>
        );
    }
}

export default Home;