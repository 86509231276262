import React, {FC} from 'react';

interface IReactIcon{
    className?:string;
    type?: 'orange' | 'blue';
    children: React.ReactNode
}

const ReactIcon:FC<IReactIcon> = ({className,type='orange',children}) => {
    return (
        <div className={`react-icon ${type === 'orange'? 'react-icon-orange': 'react-icon-blue'} ${className ? className: ''}`}>
            {children}
        </div>
    );
};

export default ReactIcon;