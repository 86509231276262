import {combineReducers,configureStore} from "@reduxjs/toolkit";
import modalsSlice from "./slices/modalsSlice";
const rootReducer = combineReducers({
    modals: modalsSlice,
});

export const store = configureStore({
    reducer: rootReducer
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch