import React from 'react';

const DisplayIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="63" viewBox="0 0 64 63" fill="none">
            <g clipPath="url(#clip0_58_1995)">
                <path d="M37.9972 57.4549V48.186H26.0029V57.4549H16.4432V61.1463H47.5569V57.4549H37.9972Z" fill="#FF7116"/>
                <path d="M21.2089 23.4744V30.2445L26.4962 27.5132V20.7432L21.2089 23.4744Z" fill="#FF7116"/>
                <path d="M12.2301 20.7432V27.5132L17.5174 30.2445V23.4744L12.2301 20.7432Z" fill="#FF7116"/>
                <path d="M19.3632 15.1519L14.406 17.7126L19.3632 20.2733L24.3203 17.7126L19.3632 15.1519Z" fill="#FF7116"/>
                <path d="M0.5 1.854V44.4944H63.5V1.854H0.5ZM30.1876 29.7605L19.3632 35.3519L8.53878 29.7605V16.5878L19.3632 10.9964L30.1876 16.5878V29.7605ZM41.4654 25.9089L38.8552 28.5191L33.5103 23.1742L38.8552 17.8293L41.4654 20.4395L38.7308 23.1742L41.4654 25.9089ZM45.1677 29.623L41.5706 28.7946L44.3502 16.7253L47.9474 17.5537L45.1677 29.623ZM50.6626 28.519L48.0525 25.9088L50.7872 23.1741L48.0525 20.4394L50.6626 17.8292L56.0076 23.1741L50.6626 28.519Z" fill="#FF7116"/>
            </g>
            <defs>
                <clipPath id="clip0_58_1995">
                    <rect width="63" height="63" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default DisplayIcon;