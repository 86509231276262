import React, {FC, useEffect, useState} from 'react';
import Accordion from '../UI/Accordion';
import {Trans, useTranslation} from "react-i18next";
import {useOnDisplay} from "../../hooks/useOnDisplay";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const QuestionsSection:FC = () => {

    const {isOnDisplay, ref} = useOnDisplay();
    const [isShowed,setIsShowed] = useState(false);

    let timeline = gsap.timeline({repeat:0});
    useEffect(()=>{
        if(isOnDisplay && !isShowed){
            setIsShowed(true)
            const UpStart = {y:50,opacity:0,duration: 0.5, stagger: 0.3};
            const InFinish = {y:0,opacity:1,duration: 0.5, stagger: 0.3};
            timeline.fromTo(".question__header", UpStart, InFinish, 0.5)
            timeline.fromTo(".question__description", UpStart, InFinish, 0.9)
            timeline.fromTo(".question__list .accordion", UpStart, InFinish, 1.3);
        }
    },[isOnDisplay]);


    const { t } = useTranslation();
    const list:string[] = t('question.items',{returnObjects:true});
    return (
        <section className={'question'} ref={ref}>
            <div className="container">
                <h2 className="h2 question__header hidden"><Trans i18nKey="question.header"/></h2>
                <div className="question__description hidden"><Trans i18nKey="question.description"/></div>
                <div className="question__list ">
                    {list.length > 0 ? list.map((item:any,index)=>{
                        return <Accordion className={'hidden'} key={index} header={<Trans i18nKey={item.header}/>}>
                            <Trans i18nKey={item.content}/>
                        </Accordion>
                    }): ''}
                </div>
            </div>
        </section>
    );
};

export default QuestionsSection;