import React, {FC} from 'react';

interface ISocialItem {
    link?: string
    children?: React.ReactNode
}

const SocialItem:FC<ISocialItem> = ({link,children}) => {
    return (
        <a className={'social__item'} href={link}>
            {children}
        </a>
    );
};

export default SocialItem;