import React, {FC} from 'react';
import Modal from "./Modal";
import {Formik, FormikHelpers} from "formik";
import * as yup from "yup";
import Input from "../UI/Input";
import {Trans} from "react-i18next";
import {changeModalErrorMessage, changeModalSuccess} from "../../redux/slices/modalsSlice";
import {useDispatchEx} from "../../hooks/redux";
import axios from 'axios';

interface IFeedbackModal {
    active: boolean;
    onClose: () => void;
}

const FeedbackModal:FC<IFeedbackModal> = ({active,onClose}) => {
    const dispatch = useDispatchEx();
    let handleForm = async (values:any, helper:FormikHelpers<any>)=>{
        try {
            const {name,content,email} = values;
            await axios.post(`mails.php`,{
                name,
                content,
                email
            });
            helper.resetForm();
            dispatch(changeModalSuccess());
        }
        catch (e) {
            dispatch(changeModalErrorMessage());
        }
        onClose();
    };
    const valSchema = yup.object().shape({
        name: yup.string().typeError('This field only accepts text').min(2,'The field must be at least 2 characters long'),
        email: yup.string().email('Wrong email').required('This field is required').min(3,'The field must be at least 3 characters long'),
        content: yup.string().typeError('This field only accepts text').min(2,'The field must be at least 2 characters long'),
    });
    return (
        <Modal active={active} type={'middle'} onClose={onClose}>
            <Formik
                validationSchema={valSchema}
                initialValues={{
                name: "",
                email: "",
                content: "",
            }} onSubmit={handleForm}>
                {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
                    <form className={""} onSubmit={handleSubmit}>
                        <div className="modal__header">
                            <Trans i18nKey="feedback-modal.header"/>
                        </div>
                        <div className="modal__input">
                            <Input name={'name'} className={'input-gray'} placeholder={'Full Name'} onChange={handleChange} onBlur={handleBlur} value={values.name} classError={'modal__error'} message={errors.name && touched.name && errors.name}/>
                        </div>
                        <div className="modal__input">
                            <span className={'modal__label'}><Trans i18nKey="feedback-modal.label"/></span>
                            <Input name={'email'} className={'input-gray'} placeholder={'Email'} onChange={handleChange} onBlur={handleBlur} value={values.email} classError={'modal__error'} message={errors.email && touched.email && errors.email}/>
                        </div>
                        <div className="modal__input">
                            <textarea className={'textarea'} placeholder={'How can we help?'} onChange={handleChange} onBlur={handleBlur} name={'content'} value={values.content} />
                            {errors.content && touched.content && <p className={'modal__error'}>{errors.content}</p>}
                        </div>
                        <div className="modal__input">
                            <button className="btn btn-rect btn-arrow"><Trans i18nKey="feedback-modal.button"/></button>
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export default FeedbackModal;