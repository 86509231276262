import React, {FC, useEffect, useRef} from 'react';
import Button from "../UI/Button";
import main from '../../assets/img/v3/main/main.png'
import ios from '../../assets/img/v3/main/ios.png'
import android from '../../assets/img/v3/main/android.png'
import {useTranslation, Trans} from "react-i18next";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
const MainSection:FC = () => {
    gsap.registerPlugin(ScrollTrigger);
    const mouseMoveImg = (e:any)=>{
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;
        const deltaX = centerX - x; // Invert the deltaX value
        const deltaY = centerY - y; // Invert the deltaY value
        let angleX = (deltaY / centerY) * 20; // Set the maximum angle of rotation to 20 degrees
        let angleY = (deltaX / centerX) * 20; // Set the maximum angle of rotation to 20 degrees
        e.currentTarget.style.transform = `rotateX(${angleX}deg) rotateY(${angleY}deg)`;
    }
    const mouseOut = (e:any)=>{
        e.currentTarget.style.transform = 'rotateX(0deg) rotateY(0deg)';
    }


    const targetSection = useRef(null);
    useEffect(()=>{
        const UpStartRight = {x:50,y:50,opacity:0,duration: 0.5, stagger: 0.5};
        const InFinishRight = {x:0,y:0,opacity:1,duration: 0.5, stagger: 0.5};
        const UpStart = {y:50,opacity:0,duration: 0.5, stagger: 0.5, ease: "power0",autoAlpha: 0};
        const InFinish = {y:0,opacity:1,duration: 0.5, stagger: 0.5, ease: "power0",autoAlpha: 1};
        const DownStart = {y:-50,opacity:0,duration: 0.5, stagger: 0.5, ease: "power0",autoAlpha: 0};
        const DownFinish = {y:0,opacity:1,duration: 0.5, stagger: 0.5, ease: "power0",autoAlpha: 1};
        let timeline = gsap.timeline({
            scrollTrigger: {
                trigger: targetSection.current
            }});
        timeline.fromTo(".main .h1", UpStart, InFinish, 0.2)
            .fromTo(".main__subtitle", UpStart, InFinish, 0.6)
            .fromTo(".main__header", UpStart, InFinish, 1.0)
            .fromTo(".main__description", UpStart, InFinish, 1.4)
            .fromTo(".main__additional", UpStart, InFinish, 1.8)
            .fromTo(".main__button", UpStart, InFinish, 2.2)
            .fromTo(".main__img", UpStartRight, InFinishRight, 0.6)
            .fromTo(".header", DownStart, DownFinish, 2.2)
        ;
    },[targetSection]);
    const { t } = useTranslation();
    return (
        <section className={'main'} ref={targetSection}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-7">
                        <div className="main__wrap">
                            <h1 className={'h1 hidden'}>Quick Touch <span>Menu</span></h1>
                            <div className="main__subtitle hidden">
                                We help increase your income
                            </div>
                            <div className="main__header hidden">
                                <Trans i18nKey="main.header"/>
                            </div>
                            <div className="main__description hidden">
                                <Trans i18nKey="main.description"/>
                            </div>
                            <div className="main__additional hidden">
                                For restaurant / cafe / bar & hotel
                            </div>
                            <div className="main__button hidden">
                                <Button className={''} type={'htmlLink'} href={'https://my.quicktouchmenu.com/'} name={t('main.button')}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 ">
                        <div className="main__img hidden">
                            <img src={main} alt="" onMouseMove={mouseMoveImg} onMouseOut={mouseOut}/>
                            <div className="main__download">
                                <a href="#"><img src={ios} alt=""/></a>
                                <a href="#"><img src={android} alt=""/></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default MainSection;