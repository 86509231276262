import React, {FC} from 'react';
import Modal from "./Modal";

interface IContactsModal {
    active: boolean;
    onClose: () => void;
}

const ContactsModal:FC<IContactsModal> = ({active,onClose}) => {
    return (
        <Modal active={active} type={'small'} onClose={onClose}>
            <div className="modal__header">
                Contacts
            </div>
            <div className="modal__list">
                <ul>
                    <li>Reg. No. 40203225299</li>
                    <li>SIA «QTM»</li>
                    <li>+371 26 324 947</li>
                    <li>info@qtm.systems</li>
                    <li>43-1 Gaujas Street, Riga, LV-1026</li>
                </ul>
            </div>
            <div className="modal__input">
                <button className="btn btn-rect modal__btn">OK</button>
            </div>
        </Modal>
    );
};

export default ContactsModal;