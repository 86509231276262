import React from 'react';

const LinkArrow = () => {
    return (
        <svg width="13" height="27" viewBox="0 0 13 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6592 18.8237L1 8.16455M11.6592 18.8237V10.6244M11.6592 18.8237H3.04984" stroke="#FF600C" strokeLinecap="round"/>
        </svg>
    );
};

export default LinkArrow;