import React, {FC, useEffect, useRef, useState} from 'react';
import independentImg from '../../assets/img/v3/independent/independent.png'
import appImg from '../../assets/img/v3/independent/apps.png';
import appImgMob from '../../assets/img/v3/independent/apps-mob.png'
import List from "../UI/List";
import {Trans} from "react-i18next";
import { t } from 'i18next';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import { useOnDisplay } from '../../hooks/useOnDisplay';


gsap.registerPlugin(ScrollTrigger);
const IndependentSection:FC = () => {
    const {isOnDisplay, ref} = useOnDisplay();
    const [isShowed,setIsShowed] = useState(false);

    const list:any[] = t('independent.list',{returnObjects:true});
    let timeline = gsap.timeline({repeat:0});
    useEffect(()=>{
        if(isOnDisplay && !isShowed){
            setIsShowed(true)
            const UpStart = {y:50,opacity:0};
            const InFinish = {y:0,opacity:1,duration: 0.5, stagger: 0.5};

            timeline
                .fromTo(".independent__h2", UpStart, InFinish, 0.5)
                .fromTo(".independent__subtitle", UpStart, InFinish, 0.9)
                .fromTo(".independent__img", UpStart, InFinish, 1.3)
                .fromTo(".independent__description", UpStart, InFinish, 1.7)
                .fromTo(".independent__apps", UpStart, InFinish, 2.1)
                .fromTo(".independent__content", UpStart, InFinish, 2.5)
            ;
        }
    },[isOnDisplay])


    return (
        <section className={'independent'} ref={ref}>
            <div className="container">
                <h2 className="h2 independent__h2 hidden">
                    <Trans i18nKey="independent.header"/></h2>
                <div className="independent__subtitle hidden">
                    <List className="independent__list list-orange-default" items={['Online menu', 'Web page', 'Mobile or web application', 'QR menu']}/>
                </div>
                <div className="independent__wrap">
                    <div className="independent__img hidden">
                        <img src={independentImg} alt=""/>
                    </div>
                    <div className="independent__description hidden">
                        <p>Create your own marketing tools to attract customers and increase orders amount</p>
                    </div>
                    <div className="independent__apps hidden">
                        <p className={'d-block d-md-none'}>Place your online marketing tools everywhere</p>
                        <picture>
                            <source media="(max-width: 767px)" srcSet={appImgMob}/>
                            <img src={appImg} alt=""/>
                        </picture>
                    </div>
                    <div className="independent__content hidden">
                        <List className="independent__list list-orange-default" items={['Internet', 'Social networks', 'App store', 'Google play', 'Messengers']}/>
                        <p className={'d-none d-md-block'}>Place your online marketing tools everywhere</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IndependentSection;