import React from 'react';

const DocsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="-10 0 63 63" fill="none">
            <path d="M14.7656 11.1973H27.0583L14.7656 1.8457V11.1973Z" fill="#FF7116"/>
            <path d="M46.1426 48.1113C47.1765 48.1113 47.9883 47.299 47.9883 46.2656V1.8457C47.9883 0.811821 47.1765 0 46.1426 0H18.457L34.4776 12.1571C36.0811 13.2429 36.9141 15.0117 36.9141 16.7344V48.1113H46.1426Z" fill="#FF7116"/>
            <path d="M0 16.7344V61.1543C0 62.1742 0.82576 63 1.8457 63H31.377C32.3969 63 33.2227 62.1742 33.2227 61.1543V16.7344C33.2227 15.7144 32.3969 14.8887 31.377 14.8887H1.8457C0.82576 14.8887 0 15.7144 0 16.7344ZM5.65968 28.3835C5.87789 27.7149 6.45467 27.228 7.15018 27.1275L12.5431 26.3431L14.9564 21.4549C15.5765 20.1931 17.6457 20.1931 18.2657 21.4549L20.6796 26.3431L26.0725 27.1275C26.768 27.228 27.3448 27.7149 27.563 28.3835C27.7793 29.0506 27.599 29.7841 27.0963 30.2743L23.1919 34.0792L24.1129 39.4505C24.2321 40.1426 23.947 40.8419 23.3794 41.2548C22.8074 41.6725 22.0489 41.7201 21.4361 41.3957L16.6113 38.8612L11.7861 41.3957C11.1699 41.7235 10.4128 41.6711 9.84327 41.2548C9.27562 40.8419 8.99059 40.1426 9.10931 39.4505L10.0307 34.0792L6.12639 30.2743C5.62363 29.7841 5.44338 29.0506 5.65968 28.3835ZM23.9941 48.1113C23.9941 49.1313 23.1684 49.957 22.1484 49.957H11.0742C10.0543 49.957 9.22852 49.1313 9.22852 48.1113C9.22852 47.0914 10.0543 46.2656 11.0742 46.2656H22.1484C23.1684 46.2656 23.9941 47.0914 23.9941 48.1113ZM7.38281 53.7715H25.8398C26.8598 53.7715 27.6855 54.5972 27.6855 55.6172C27.6855 56.6371 26.8598 57.4629 25.8398 57.4629H7.38281C6.36287 57.4629 5.53711 56.6371 5.53711 55.6172C5.53711 54.5972 6.36287 53.7715 7.38281 53.7715Z" fill="#FF7116"/>
            <path d="M13.8317 33.7459L13.3794 36.3899L15.7534 35.1427C16.0221 34.9999 16.3157 34.9297 16.6113 34.9297C16.9069 34.9297 17.2006 34.9999 17.4693 35.1427L19.8432 36.3899L19.3909 33.7459C19.2881 33.1475 19.4861 32.5361 19.9206 32.1126L21.8403 30.2419L19.1891 29.856C18.5868 29.7694 18.0677 29.3912 17.799 28.8466L16.6113 26.4419L15.4236 28.8466C15.155 29.3907 14.6359 29.7694 14.0341 29.856L11.3823 30.2414L13.302 32.1126C13.7366 32.5361 13.9346 33.1475 13.8317 33.7459Z" fill="#FF7116"/>
        </svg>
    );
};

export default DocsIcon;