import React from 'react';

const LinkedinIcon = () => {
    return (
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.5829 0.0859624C16.1077 0.518636 12.1639 2.07693 8.60188 4.82011C7.49122 5.67542 5.40534 7.81035 4.54184 8.97559C3.47618 10.4136 3.03963 11.1269 2.30539 12.6296C1.20354 14.8846 0.611408 16.7809 0.225753 19.29C-0.0511623 21.0913 -0.0511623 23.9791 0.225753 25.7803C0.611408 28.2895 1.20354 30.1858 2.30539 32.4407C3.45866 34.801 4.67541 36.4923 6.61593 38.4329C8.55653 40.3734 10.2479 41.5902 12.6081 42.7434C14.8631 43.8453 16.7594 44.4374 19.2685 44.8231C21.0697 45.1 23.9576 45.1 25.7588 44.8231C28.2679 44.4374 30.1643 43.8453 32.4192 42.7434C34.7794 41.5902 36.4708 40.3734 38.4114 38.4329C40.3519 36.4923 41.5687 34.801 42.7219 32.4407C43.8238 30.1858 44.4159 28.2895 44.8016 25.7803C45.0785 23.9791 45.0785 21.0913 44.8016 19.29C44.4159 16.7809 43.8238 14.8846 42.7219 12.6296C41.5687 10.2694 40.3519 8.57805 38.4114 6.63744C36.4708 4.69692 34.7794 3.48017 32.4192 2.3269C30.1649 1.22532 28.2315 0.62139 25.804 0.260652C24.3939 0.0510069 21.8308 -0.0347531 20.5829 0.0859624ZM14.6069 9.69081C16.1829 10.0249 17.1074 11.3409 16.8627 12.9017C16.6273 14.4028 15.3617 15.3098 13.6414 15.2101C12.1687 15.1249 11.1444 14.2729 10.8877 12.9197C10.7922 12.4159 10.9025 11.5805 11.1201 11.1597C11.7126 10.014 13.1918 9.39092 14.6069 9.69081ZM31.1602 17.1714C32.1359 17.3705 32.792 17.6655 33.5853 18.2618C34.5744 19.0054 35.2719 20.1462 35.7015 21.7233L35.9346 22.5792L35.9648 28.1043L35.9949 33.6294H33.3045H30.6142L30.6119 29.425C30.6107 27.1127 30.5726 24.84 30.5273 24.3747C30.327 22.3163 29.4868 21.3674 27.8646 21.3674C26.9301 21.3674 26.199 21.714 25.6449 22.4198C24.9653 23.2858 24.979 23.1562 24.979 28.7087V33.6294H22.2495H19.52V25.5288V17.4283H22.2495H24.979L24.9819 18.5069L24.9846 19.5855L25.1721 19.3214C25.8506 18.3657 27.1394 17.4807 28.2444 17.2116C28.8369 17.0674 30.5357 17.0439 31.1602 17.1714ZM16.6144 25.5288V33.6294H13.8848H11.1553V25.5288V17.4283H13.8848H16.6144V25.5288Z" fill="#000000"/>
        </svg>
    );
};

export default LinkedinIcon;