import React from 'react';

const YoutubeIcon = () => {
    return (
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.5226 0.0849582C14.9985 0.623454 10.118 2.97893 6.27679 6.96046C3.65259 9.68056 1.8041 12.9152 0.817567 16.514C-0.245171 20.3901 -0.271655 24.2468 0.737056 28.209C1.1367 29.7787 1.53785 30.8412 2.36011 32.5079C4.80296 37.4594 8.94612 41.2991 14.1286 43.4144C16.9057 44.5479 20.3292 45.1418 23.3467 45.0135C30.7204 44.7001 37.2354 41.0132 41.362 34.8185C42.0657 33.7621 43.2209 31.47 43.6732 30.2328C44.7019 27.4187 45.1807 24.0404 44.9688 21.0913C44.8484 19.4151 44.6884 18.4192 44.2846 16.8332C43.8894 15.2808 43.487 14.2159 42.6604 12.5343C41.4816 10.1365 40.0709 8.21598 38.0715 6.28707C36.2744 4.55333 34.6931 3.44695 32.4097 2.32587C30.2123 1.24695 28.183 0.609024 25.8105 0.251258C24.4913 0.0524021 21.7437 -0.0340036 20.5226 0.0849582ZM31.2218 14.228C32.7479 14.7456 33.8942 15.9879 34.2539 17.5139C34.4499 18.3452 34.4499 26.6971 34.2539 27.5284C33.8868 29.0857 32.6654 30.3682 31.0898 30.8505C30.5939 31.0023 30.073 31.0121 22.5108 31.0121H14.4598L13.8439 30.8167C12.3013 30.3273 11.1331 29.0785 10.7678 27.5284C10.5718 26.6971 10.5718 18.3452 10.7678 17.5139C11.1999 15.6805 12.7328 14.3019 14.5918 14.0748C14.9064 14.0363 18.6481 14.0114 22.9068 14.0195C30.6391 14.034 30.6507 14.0343 31.2218 14.228ZM19.4312 22.5211V26.1125L22.4449 24.3599C24.1023 23.396 25.4585 22.5686 25.4585 22.5211C25.4585 22.4737 24.1023 21.6463 22.4449 20.6823L19.4312 18.9298V22.5211Z" fill="#000000"/>
        </svg>
    );
};

export default YoutubeIcon;