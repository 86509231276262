import {createSlice} from "@reduxjs/toolkit";


interface modalsState {
    modalFeedback: boolean;
    modalLogin: boolean;
    modalRegistration: boolean;
    modalContact: boolean;
    modalVideo: boolean;
    modalSuccess: boolean;
    modalErrorMessage: boolean;
    timeline: any;
    isLoading: boolean;
    error: string
}

const initialState: modalsState = {
    modalFeedback: false,
    modalLogin: false,
    modalRegistration: false,
    modalContact:  false,
    modalVideo: false,
    modalSuccess: false,
    modalErrorMessage: false,
    isLoading: false,
    timeline:0,
    error: ''
};


const modalsSlice = createSlice({
    name:'modalsSlice',
    initialState,
    reducers:{
        changeTimeLine(state,action){
            state.timeline = action.payload;
        },
        changeModalFeedback(state){
            state.modalFeedback = !state.modalFeedback;
        },
        changeModalLogin(state){
            state.modalLogin = !state.modalLogin;
        },
        changeModalRegistration(state){
            state.modalRegistration = !state.modalRegistration;
        },
        changeModalContact(state){
            state.modalContact = !state.modalContact;
        },
        changeModalVideo(state){
            state.modalVideo = !state.modalVideo;
        },
        changeModalSuccess(state){
            state.modalSuccess = !state.modalSuccess;
        },
        changeModalErrorMessage(state){
            state.modalErrorMessage = !state.modalErrorMessage;
        },

    }
});

export const {changeTimeLine,changeModalFeedback,changeModalContact,changeModalLogin,changeModalRegistration,changeModalVideo,changeModalSuccess,changeModalErrorMessage} = modalsSlice.actions;
export default modalsSlice.reducer;