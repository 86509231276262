import React, {FC, useEffect, useState} from 'react';

import thinkingImg from '../../assets/img/v3/thinking/thinking.png';
import thinkingAddImg from '../../assets/img/v3/thinking/thinking-img.png';
import thinkingMob1 from '../../assets/img/v3/thinking/1.png';
import thinkingMob2 from '../../assets/img/v3/thinking/2.png';
import {Trans} from "react-i18next";

import List from "../UI/List";
import {useOnDisplay} from "../../hooks/useOnDisplay";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import LinkArrow from "../Icons/LinkArrow";
gsap.registerPlugin(ScrollTrigger);
const ThinkingSection:FC = () => {
    const {isOnDisplay, ref} = useOnDisplay();
    const [isShowed,setIsShowed] = useState(false);

    let timeline = gsap.timeline({repeat:0});
    useEffect(()=>{
        if(isOnDisplay && !isShowed){
            setIsShowed(true)
            const UpStart = {y:50,opacity:0,duration: 0.5, stagger: 0.5};
            const InFinish = {y:0,opacity:1,duration: 0.5, stagger: 0.5};
            const UpStartLeft = {x:-50,opacity:0,duration: 0.5, stagger: 0.5};
            const InFinishLeft = {x:0,opacity:1,duration: 0.5, stagger: 0.5};
            const UpStartRight = {x:50,opacity:0,duration: 0.5, stagger: 0.5};
            const InFinishRight = {x:0,opacity:1,duration: 0.5, stagger: 0.5};
            timeline.fromTo(".thinking__h2", UpStart, InFinish, 0.5)
            timeline.fromTo(".thinking__description", UpStart, InFinish, 0.9)
            timeline.fromTo(".thinking__img", UpStart, InFinish, 1.3)
            timeline.fromTo(".thinking__inner-first .thinking__content", UpStartLeft, InFinishLeft, 1.7)
            timeline.fromTo(".thinking__content-second", UpStartRight, InFinishRight, 2.1)
            timeline.fromTo(".thinking__additional-wrap", UpStartLeft, InFinishLeft, 2.5)
            timeline.fromTo(".thinking__additional-img", UpStartRight, InFinishRight, 2.9)
            ;
        }
    },[isOnDisplay]);
    return (
        <section className={'thinking'} ref={ref}>
            <div className="container">
                <h2 className="h2 thinking__h2 hidden"><Trans i18nKey="thinking.header"/></h2>
                <div className="thinking__description hidden"><Trans i18nKey="thinking.description"/></div>
                <div className="thinking__wrap">
                    <div className="row">
                        <div className="col-xl-4 col-md-12 col-12 order-2 order-xl-1 ">
                            <div className="thinking__inner thinking__inner-first">
                                <div className="thinking__img d-xl-none hidden">
                                    <img src={thinkingMob1} alt=""/>
                                </div>
                                <div className="thinking__content hidden">
                                    <div className="thinking__subtitle">Web application</div>
                                    <div className="thinking__header">ONLINE MENU</div>
                                    <div className="thinking__list">
                                        <List className={'list-orange-default'} items={['Dishes', 'Description', 'Gallery', 'Food basket', 'Stop lists', 'Feedback', 'Multilingualism']}/>
                                        <div className="thinking__link">
                                            <a href="">More <LinkArrow/></a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 d-none d-xl-flex order-1 order-xl-2">
                            <div className="thinking__img wow fadeIn delay-2">
                                <img src={thinkingImg} alt=""/>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-12 col-12 order-3 order-xl-3">
                            <div className="thinking__inner">
                                <div className="thinking__content thinking__content-second hidden">
                                    <div className="thinking__subtitle">Mobile application</div>
                                    <div className="thinking__header">ONLINE WAITER</div>
                                    <div className="thinking__list">
                                        <List className={'list-orange-default'} items={['Multilingual', 'Quick online ordering', 'Easy online payment and online tipping', 'Instant orders and payments notification', 'Stop lists', 'Stop lists']}/>
                                        <div className="thinking__link">
                                            <a href="">More <LinkArrow/></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="thinking__img thinking__img-second d-xl-none">
                                    <img src={thinkingMob2} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="thinking__additional">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="thinking__additional-wrap hidden">
                                <div className="thinking__subtitle">ORM system</div>
                                <div className="thinking__header">ONLINE MANAGER</div>
                                <div className="thinking__additional-list">
                                    <List className={'list-orange-default'} items={['Manage all types of online and bookings',
                                        'Manage all types of payment and tips',
                                        'Manage your customers base',
                                        'Manage feedback and reviews',
                                        'Create promotions and special offers',
                                        'Accept messages from customers',
                                        'Edit dishes | categories | photos | descrition',
                                        'Edit design settings']}/>
                                    <div className="thinking__link">
                                        <a href="">More <LinkArrow/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thinking__additional-img hidden">
                                <img src={thinkingAddImg} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ThinkingSection;