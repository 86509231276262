import React, {FC} from 'react';

interface IModal {
    active: boolean;
    type: 'middle'| 'small';
    children: JSX.Element[] | string | JSX.Element;
    onClose: () => void
}

const Modal:FC<IModal> = ({active,children, type='middle',onClose}) => {
    return (
        <div className={`modal modal-${type} ${active ? 'active' : ''}`} >
            <div className="modal__overlay" onClick={onClose}/>
            <div className="modal__wrap">
                <div className="modal__close" onClick={onClose}>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L20 20" stroke="black"/>
                        <path d="M1 20L20 1" stroke="black"/>
                    </svg>
                </div>

                <div className="modal__content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;