import React, {FC, useEffect, useState} from 'react';
import Logo from "./Logo";
import Menu, { IMenuItem } from "./Menu/Menu";
import {useTranslation} from "react-i18next";
import {useDispatchEx} from "../hooks/redux";
import {Link} from "react-router-dom";
import ChangerLang from "./Menu/ChangerLang";
import SmoothScrollbar from "smooth-scrollbar";

interface IHeader {
    className?: string;
    attach?:boolean;
    out?: boolean;
}

const Header:FC<IHeader> = ({className='',attach,out}) => {
    const [open,setOpen] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatchEx();

    const items:IMenuItem[] = [
/*        {
            name: t('menu.items.0') as string,
            linkType: "link",
            link: "/",

        },*/
        {
            name: t('menu.items.1') as string,
            linkType: "link",
            link: "/#price",
            onClick: ()=>{
                if(open){
                    setOpen(false);
                    document.body.classList.remove('hidden-scroll');
                    document.querySelector('html')?.classList.remove('hidden-scroll');
                }
                SmoothScrollbar.init(document.querySelector('.scroll-container')!).scrollIntoView(document.getElementById('price')!, {
                    //  duration: options.duration,
                    offsetLeft:  0,
                    offsetTop:  0
                });
            }

            //smooth: true
        },
        {
            name: t('menu.items.2') as string,
            linkType: "a",
            link: "https://my.quicktouchmenu.com/",
        },
        {
            name: t('menu.items.3') as string,
            linkType: "a",
            link: "https://my.quicktouchmenu.com/",
        },
    ];

    useEffect(()=>{
        if(open){
            document.body.classList.add('hidden-scroll');
            document.querySelector('html')?.classList.add('hidden-scroll');
        }
        else {
            document.body.classList.remove('hidden-scroll');
            document.querySelector('html')?.classList.remove('hidden-scroll');
        }
    },[open]);

    return (
        <header className={`header ${attach ? 'active' : ''} ${out ? 'out' : ''} ${open ? "active-menu" : ""} ${className}`}>
            <div className="header__inner">
                <div className="container">
                    <div className="header__wrap">
                        <Link to={"/"}><Logo className={`header__logo ${open ? "active" : ""}`}/></Link>
                        <div className={`header__lang ${open ? "active" : ""}`}>
                            <ChangerLang listClass={'header__lang-list'} linkClass={'header__lang-link'}/>
                        </div>
                        <Menu open={open} setOpen={setOpen} items={items} className={`header__menu`} classNameMenu={`${attach ? "active-header" : ""}`}/>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;