import React, {FC, useEffect, useState} from 'react';

import qrImg from '../../assets/img/v3/qr.svg'
import {Trans} from "react-i18next";
import {useOnDisplay} from "../../hooks/useOnDisplay";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const DemoSection:FC = () => {
    const {isOnDisplay, ref} = useOnDisplay();
    const [isShowed,setIsShowed] = useState(false);

    let timeline = gsap.timeline({repeat:0});
    useEffect(()=>{
        if(isOnDisplay && !isShowed){
            setIsShowed(true)
            const UpStart = {y:50,opacity:0,duration: 0.5, stagger: 0.5};
            const InFinish = {y:0,opacity:1,duration: 0.5, stagger: 0.5};
            timeline.fromTo(".demo-section", UpStart, InFinish, 0.2)
            timeline.fromTo(".demo-section__img", UpStart, InFinish, 0.5)
            timeline.fromTo(".demo-section__header", UpStart, InFinish, 0.9)
            timeline.fromTo(".demo-section__subtitle", UpStart, InFinish, 1.3)
            timeline.fromTo(".demo-section__description", UpStart, InFinish, 1.7)
            timeline.fromTo(".demo-section__btn", UpStart, InFinish, 2.1)
            ;
        }
    },[isOnDisplay]);
    return (
        <section className={'demo-section hidden'} ref={ref}>
            <div className="container">
                <div className="demo-section__wrap ">
                    <div className="demo-section__img d-none d-lg-block hidden">
                        <img src={qrImg} alt=""/>
                    </div>
                    <div className="demo-section__header hidden">
                        <h2 className="h2 demo-section__h2 order-1 "><Trans i18nKey="demo.header"/></h2>
                        <div className="demo-section__img order-3 order-md-2 d-flex d-lg-none ">
                            <img src={qrImg} alt=""/>
                        </div>
                        <div className="demo-section__subtitle order-2 order-md-3 hidden">
                            Want to see a demo?
                        </div>
                        <div className="demo-section__description order-4 hidden"><Trans i18nKey="demo.desc"/></div>

                        <div className="demo-section__btn order-5 hidden">
                            <a href="https://picburg.quicktouchmenu.com">https://picburg.quicktouchmenu.com</a>
                        </div>

                    </div>

                </div>
            </div>
        </section>
    );
};

export default DemoSection;