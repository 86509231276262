import React, {FC} from 'react';
import Modal from "./Modal";

interface ISmallModal {
    active: boolean;
    header?: string;
    content?: React.ReactNode
    onClose: () => void;
}

const SmallModal:FC<ISmallModal> = ({header,content,active,onClose}) => {
    return (
        <Modal active={active} type={'small'} onClose={onClose}>
            <div className="modal__header">
                {header}
            </div>
            <div className="modal__list" style={{textAlign:"center"}}>
                {content}
            </div>
            <div className="modal__input">
                <button className="btn btn-rect modal__btn" onClick={onClose}>OK</button>
            </div>
        </Modal>
    );
};

export default SmallModal;