import React, {FC} from 'react';

interface IInput {
    className?: string;
    classError?: string;
    name: string;
    type?: "text" | "password" | "email";
    placeholder: string;
    message?: string | undefined | false | any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    value?: string;
}

const Input:FC<IInput> = ({name,className,classError,type="text",placeholder,onBlur,onChange,value,message}) => {
    return (//input-gray
        <div className={'input-block'}>
            <input className={`input ${className ? className :""}`} type={type} placeholder={placeholder} onChange={onChange} onBlur={onBlur} name={name} value={value} />
            {message && <p className={classError}>{message}</p>}
        </div>
    );
};

export default Input;