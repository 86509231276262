import React, {FC, useEffect, useState} from 'react';
import PeopleIcon from "../Icons/reduce/PeopleIcon";
import DisplayIcon from "../Icons/reduce/DisplayIcon";
import TouchIcon from "../Icons/reduce/TouchIcon";
import DocsIcon from "../Icons/reduce/DocsIcon";
import HandIcon from "../Icons/reduce/HandIcon";
import {Trans} from "react-i18next";
import ReactIcon from "../UI/ReactIcon";
import {useOnDisplay} from "../../hooks/useOnDisplay";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const ReducingSection:FC = () => {
    const {isOnDisplay, ref} = useOnDisplay();
    const [isShowed,setIsShowed] = useState(false);

    let timeline = gsap.timeline({repeat:0});
    useEffect(()=>{
        if(isOnDisplay && !isShowed){
            setIsShowed(true)
            const UpStart = {y:50,opacity:0,duration: 0.5, stagger: 0.5};
            const InFinish = {y:0,opacity:1,duration: 0.5, stagger: 0.5};
            timeline.fromTo(".reduce__h2", UpStart, InFinish, 0.5)
            timeline.fromTo(".reduce__item", UpStart, InFinish, 0.9);
        }
    },[isOnDisplay]);
    return (
        <section className={'reduce'} ref={ref}>
            <div className="container">
                <h2 className="h2 reduce__h2 hidden"><Trans i18nKey="reduce-section.header"/></h2>
                <div className="reduce__wrap">

                    <div className="reduce__item order-2 order-lg-1 hidden">
                        <div className="reduce__item-wrap ">
                            <ReactIcon className={'reduce__img'}>
                                <PeopleIcon/>
                            </ReactIcon>
                            <span><Trans i18nKey="reduce-section.items.0"/></span>
                        </div>
                    </div>
                    <div className="reduce__item order-1 order-lg-2 hidden">
                        <div className="reduce__item-wrap">
                            <ReactIcon type={'blue'} className={'reduce__img'}>
                                <DisplayIcon/>
                            </ReactIcon>
                            <span><Trans i18nKey="reduce-section.items.1"/></span>
                        </div>
                    </div>
                    <div className="reduce__item order-4 order-lg-3 hidden">
                        <div className="reduce__item-wrap">
                            <ReactIcon className={'reduce__img'}>
                                <TouchIcon/>
                            </ReactIcon>
                            <span><Trans i18nKey="reduce-section.items.2"/></span>
                        </div>
                    </div>
                    <div className="reduce__item order-5 order-lg-4 hidden">
                        <div className="reduce__item-wrap">
                            <ReactIcon type={'blue'} className={'reduce__img'}>
                                <DocsIcon/>
                            </ReactIcon>
                            <span><Trans i18nKey="reduce-section.items.3"/></span>
                        </div>
                    </div>
                    <div className="reduce__item order-3 order-lg-5 hidden">
                        <div className="reduce__item-wrap">
                            <ReactIcon type={'blue'} className={'reduce__img'}>
                                <HandIcon/>
                            </ReactIcon>
                            <span><Trans i18nKey="reduce-section.items.4"/></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ReducingSection;