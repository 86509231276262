import React, {FC} from 'react';
import InstaIcon from "../Icons/social/InstaIcon";
import YoutubeIcon from "../Icons/social/YoutubeIcon";
import FacebookIcon from "../Icons/social/FacebookIcon";
import LinkedinIcon from "../Icons/social/LinkedinIcon";
import SocialItem from "./SocialItem";

interface ISocial {
    className?: string;
}

const Social:FC<ISocial> = ({className}) => {
    return (
        <div className={`social ${className ? className: ""}`}>
            <SocialItem link={'https://instagram.com/quicktouchmenu'} >
                <InstaIcon/>
            </SocialItem>
            <SocialItem link={'https://youtube.com/@quicktouchmanager7181'} >
                <YoutubeIcon/>
            </SocialItem>
            <SocialItem link={'https://www.facebook.com/quicktouchmenu'} >
                <FacebookIcon/>
            </SocialItem>
            <SocialItem link={'https://www.linkedin.com/company/qtm-systems/'} >
                <LinkedinIcon/>
            </SocialItem>
        </div>
    );
};

export default Social;