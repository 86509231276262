import React from 'react';

const PaymentsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px"
             viewBox="20 20 80 80" xmlSpace="preserve">
            <path className="st1" fill="#FFFFFF"
                  d="M57.7,21c5,0.7,11.8,3,14.8,5.2c0.9,0.6,0.9,1.8,0.1,2.2c-0.5,0.2-0.9,0.1-3.4-1.1c-6.3-3.2-13.3-4.5-20.2-3.9   c-5.2,0.5-8.5,1.3-12.8,3.4c-6.6,3.1-11.2,7.9-12.9,13.6c-0.6,2-0.6,6,0,8.1c1.9,6.8,8.1,12.6,16.8,15.4c2.3,0.8,4.9,1.3,7.3,1.6   c1,0.1,1.4,0.3,1.7,0.7c0.3,0.5,0.3,0.6,0,1.2c-0.5,0.8-1.7,0.8-5.1,0.1C31.9,65.1,23.1,58,20.7,48.9c-0.7-2.6-0.7-6.4,0-8.9   c2.5-9.5,12.3-17,25.2-19C48.4,20.7,55.2,20.7,57.7,21z"/>
            <path className="st0" fill="#FFFFFF"
                  d="M71.3,29.9c3.6,0.9,9.1,2.2,12,2.9c3,0.7,5.7,1.5,6.1,1.7c1.1,0.6,2.2,2,2.5,3.4c0.3,1.2,0.2,1.7-2.1,11   c-1.3,5.4-2.4,10.1-2.6,10.5c-0.1,0.5,0.1,0.9,0.5,1.2l3.2,1.6c4.5,2.3,5.3,2.9,6.1,4.7c1.5,3.1,1.8,6.9,2.5,27.6l0.1,4.2   c0,0.5-0.2,0.9-0.6,1.2c-1.5,1-2.1-0.2-2.1-4c0-3-0.7-18.6-1-22.1c-0.2-2.9-0.9-5.5-1.7-6.5c-0.4-0.5-7.2-4.3-7.8-4.3   c-0.1,0-0.5,1.5-1,3.3l-0.1,0.4c-0.4,1.9,0,3.8,1.2,5.3c1.1,1.3,1.9,2.4,1.9,2.5c0,0.4-1.7,1.5-1.9,1.3c-0.1-0.1-3.1-3.5-6.5-7.7   c-3.4-4.1-6.5-7.6-6.8-7.8c-1-0.4-1.7-0.3-2.5,0.3c-0.6,0.5-0.8,0.8-0.8,1.5c0,1,0.2,1.4,6.2,11.9c2.3,4,4.3,7.7,4.5,8.2   c0.2,0.5,0.3,2.1,0.3,3.9c0,5.7,1.4,8.8,4.9,11.2c1.5,1,1.8,1.7,1,2.5c-0.5,0.5-1.1,0.5-2.3-0.3c-2.3-1.5-3.9-3.3-5-5.9   c-0.3-0.6-0.5-1.1-0.5-1.2c0,0-0.4,0.3-0.9,0.8c-1,1.1-2.4,1.8-3.7,1.8c-0.5,0-2.7-0.4-4.9-1c-2.1-0.5-7.4-1.8-11.7-2.8   c-7.7-1.8-9.1-2.3-9.9-3.2c-0.6-0.7-1.4-2.6-1.4-3.4c0-0.8,3.2-14.1,11.4-47.8c1.3-5.5,1.4-5.7,2.3-6.8c0.9-1,2.4-1.7,3.6-1.8   C64.4,28.3,67.7,29,71.3,29.9z"/>
            <path className="st2"
                  fill="#ff7116"
                  d="M63.2,31.2c-0.3,0.1-0.7,0.5-0.9,0.8c-0.4,0.6-1.2,3.3-1,3.4c0,0,4.7,1.2,10.4,2.6c5.7,1.4,11.8,2.9,13.7,3.3   c1.8,0.4,3.4,0.7,3.5,0.6c0.1-0.1,0.3-0.8,0.5-1.7c0.3-1.7,0.1-2.8-0.9-3.4c-0.5-0.3-23.5-6-24.3-6C64,31,63.5,31.1,63.2,31.2z"/>
            <path className="st1" fill="#FFFFFF"
                  d="M47.2,32.8c3.7,6.9,3.6,16.9-0.2,23.7c-0.5,0.8-0.7,1-1.4,1c-1.3,0-1.5-0.9-0.7-2.6c1.9-4,2.5-6.6,2.4-10.7   c0-3.6-0.7-6.6-2.1-9.5c-1.2-2.6-1.1-3.5,0.5-3.3C46.3,31.3,46.6,31.6,47.2,32.8z"/>
            <path className="st1" fill="#FFFFFF"
                  d="M41.3,34.3c0.6,0.7,1.6,2.8,2.2,4.7c0.5,1.5,0.6,2.3,0.6,5.2c0,2.5-0.1,3.8-0.4,5c-0.5,1.8-1.7,4.5-2.3,5.2   c-0.5,0.6-1.5,0.5-1.9-0.1c-0.3-0.5-0.3-0.7,0.5-2.4c1.3-2.8,1.7-4.6,1.7-7.5c0-2.8-0.4-4.7-1.6-7.3c-0.8-1.8-0.9-2.3-0.3-2.8   C40.2,33.8,40.8,33.8,41.3,34.3z"/>
            <path className="st3" fill="#FFFFFF"
                  d="M79.3,36c0.6,0.3,0.8,1.3,0.3,1.8c-0.6,0.6-0.8,0.6-4.5-0.3c-3.4-0.8-4.1-1.2-3.8-2.3c0.3-1.1,0.9-1.1,4.4-0.3   C77.5,35.5,79.1,35.9,79.3,36z"/>
            <path className="st1" fill="#FFFFFF"
                  d="M36.4,37.1c0.8,0.8,1.6,2.8,2,4.7c0.5,2.8,0.1,6.2-1.2,8.7c-0.8,1.7-1.4,2.1-2.3,1.7c-0.9-0.4-0.9-1,0-2.9   c1.5-3.3,1.5-6.5,0-9.7c-0.9-1.8-0.9-2.3-0.2-2.8C35.4,36.2,35.6,36.2,36.4,37.1z"/>
            <path className="st2"
                  fill="#ff7116"
                  d="M58.1,48.5c-1.4,5.7-3.6,14.9-4.9,20.4c-1.3,5.5-2.4,10-2.4,10c0,0,1.3,0.3,2.7,0.7c4.4,1.1,20.8,5,22.9,5.5   c1.2,0.3,1.9,0.4,2,0.2c0.3-0.5,0.1-2.2-0.4-3.2c-0.3-0.6-2.3-4.1-4.4-7.9c-5.7-10-5.9-10.4-5.9-12.2c0-1.3,0.1-1.6,0.8-2.4   c2.1-2.6,5.1-3,7.4-0.8c0.5,0.4,2.1,2.3,3.7,4.3c1.6,1.9,3,3.5,3.1,3.6c0.1,0.1,0.9-2.7,1.7-6.1c0.8-3.5,2-8.4,2.7-11   c0.6-2.6,1.2-4.8,1.1-4.8c0,0-27.4-6.7-27.5-6.7C60.6,38.1,59.5,42.8,58.1,48.5z"/>
            <path className="st1" fill="#FFFFFF"
                  d="M31.9,40c1.4,2.1,1.6,5.3,0.6,7.7c-0.7,1.6-1.2,2.1-2,2.1c-1.1,0-1.4-1-0.7-2.2c1.1-1.9,1.1-4.8,0-6.4   c-0.8-1.2-0.3-2.3,0.9-2.1C31.1,39.1,31.5,39.4,31.9,40z"/>
            <path className="st2"
                  fill="#ff7116"
                  d="M49.8,82.7c-0.4,1.9-0.4,2.8,0.3,3.5c0.5,0.6,1.7,0.9,12.6,3.6c10.7,2.6,12.2,2.9,12.9,2.6   c0.4-0.1,1-0.5,1.2-0.8c0.3-0.5,1.2-3.4,1-3.5c0,0-2.5-0.7-5.5-1.4c-3-0.7-9.1-2.2-13.6-3.3c-4.5-1.1-8.2-2-8.3-2   C50.2,81.5,50,82,49.8,82.7z"/>
            <path className="st1" fill="#FFFFFF"
                  d="M64.1,86.7c0.4,0.4,0.1,1.3-0.4,1.5c-0.9,0.4-1.7-0.5-1.3-1.4C62.8,86.3,63.7,86.2,64.1,86.7z"/>
        </svg>
    );
};

export default PaymentsIcon;