import { useState, useRef, useEffect, RefObject, useCallback } from 'react';
import { useSelectorEx } from "./redux";

interface OnDisplayHook {
    isOnDisplay: boolean;
    ref: RefObject<HTMLElement>;
}

export const useOnDisplay = (): OnDisplayHook => {
    const [isOnDisplay, setIsOnDisplay] = useState(false);
    const ref = useRef<HTMLElement>(null);
    const timelineEx = useSelectorEx(state => state.modals.timeline);

    const onScroll = useCallback(() => {
        if (ref.current) {
            const { top, bottom } = ref.current.getBoundingClientRect();
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            if (top < windowHeight && bottom > 0) {
                setIsOnDisplay(true);
            } else {
                setIsOnDisplay(false);
            }
        }
    }, [setIsOnDisplay]);

    useEffect(() => {
        if(!isOnDisplay){
            window.addEventListener('scroll', onScroll);
            onScroll(); // Check on initial render

            return () => {
                window.removeEventListener('scroll', onScroll);
            };
        }

    }, [timelineEx]);

    return { isOnDisplay, ref };
};