import React, {useEffect} from 'react';
import tipsMob from "../../assets/img/v3/tipsMob.png";
import tips from "../../assets/img/v3/tips.png";
import {Trans} from "react-i18next";
import List from "../UI/List";
import { t } from 'i18next';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const TipsSection = () => {
    const list:string[] = t('tips.list',{returnObjects:true});
    const listEx:JSX.Element[] = list.map(item=><Trans i18nKey={item} />);
    let timeline = gsap.timeline({repeat:0});
    useEffect(()=>{
        const UpStart = {y:50,opacity:0,duration: 0.5, stagger: 0.5};
        const InFinish = {y:0,opacity:1,duration: 0.5, stagger: 0.5};
        timeline.fromTo(".tips__h2", UpStart, InFinish, 0.5)
        timeline.fromTo(".tips__description", UpStart, InFinish, 0.9)
        timeline.fromTo(".tips__img", UpStart, InFinish, 1.3)
        timeline.fromTo(".tips__content", UpStart, InFinish, 1.7)
    },[])
    return (
        <section className={'tips'}>
            <div className="container">
                <div className="tips__header">
                    <h2 className="h2 tips__h2"><Trans i18nKey="tips.header"/></h2>
                    <div className="tips__description"><Trans i18nKey="tips.description"/></div>
                </div>
                <div className="tips__wrap">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="tips__img-wrap">
                                <div className="tips__img wow fadeIn delay-1">
                                    <picture>
                                        <source srcSet={tipsMob} media="(max-width: 768px)" />
                                        <img src={tips} alt=""/>
                                    </picture>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 ">
                            <div className="tips__inner">
                                <div className="tips__content">
                                    <List className={'tips__list'} items={listEx} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default TipsSection;