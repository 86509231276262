import React, {FC, useEffect, useState} from 'react';
import costsImg from '../../assets/img/newimages/nocosts.svg'
import Plate from "../Plate/Plate";
import {IPlateItem} from "../Plate/PlateItem";
import {Trans} from "react-i18next";
import {useOnDisplay} from "../../hooks/useOnDisplay";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const NoCostSection:FC = () => {
    const {isOnDisplay, ref} = useOnDisplay();
    const [isShowed,setIsShowed] = useState(false);

    let timeline = gsap.timeline({repeat:0});
    useEffect(()=>{
        if(isOnDisplay && !isShowed){
            setIsShowed(true)
            const UpStart = {y:50,opacity:0,duration: 0.5, stagger: 0.5};
            const InFinish = {y:0,opacity:1,duration: 0.5, stagger: 0.5};
            const UpStartLeft = {x:-50,opacity:0,duration: 0.5, stagger: 0.5};
            const InFinishLeft = {x:0,opacity:1,duration: 0.5, stagger: 0.5};
            timeline.fromTo(".costs-section__word", UpStart, InFinish, 0.5)
            timeline.fromTo(".costs-section__list span", UpStart, InFinish, 0.9)
            timeline.fromTo(".costs-section__description", UpStart, InFinish, 1.3)
            timeline.fromTo(".costs-section__wrap .plate", UpStartLeft, InFinishLeft, 1.7)
            timeline.fromTo(".costs-section__wrap .plate__item", UpStartLeft, InFinishLeft, 2.1)
            ;
        }
    },[isOnDisplay]);


    const plateItems:IPlateItem[] = [
        {
            header: <Trans i18nKey="costs-section.plate.0.header"/>,
            count: <Trans i18nKey="costs-section.plate.0.count"/>,
            desc: <Trans i18nKey="costs-section.plate.0.desc"/>,
            className: ''
        },
/*        {
            header: <Trans i18nKey="costs-section.plate.1.header"/>,
            count: <Trans i18nKey="costs-section.plate.1.count"/>,
            desc: <Trans i18nKey="costs-section.plate.1.desc"/>,
            className: 'wow fadeIn delay-2'
        },*/
        {
            header: <Trans i18nKey="costs-section.plate.2.header"/>,
            count: <Trans i18nKey="costs-section.plate.2.count"/>,
            desc: <Trans i18nKey="costs-section.plate.2.desc"/>,
            className: ''
        },
    ];

    return (
        <section id={'price'} className={'costs-section'} ref={ref}>
            <div className="container">
                <div className="costs-section__header ">
                    <div className="costs-section__word hidden">
                        <img src={costsImg} alt=""/>
                    </div>
                    <div className="costs-section__list">
                        <span className={'hidden'}><Trans i18nKey="costs-section.list.0"/></span>
                        <span className={'hidden'}><Trans i18nKey="costs-section.list.1"/></span>
                        <span className={'hidden'}><Trans i18nKey="costs-section.list.2"/></span>
                    </div>
                </div>
                <div className="costs-section__description hidden"><Trans i18nKey="costs-section.description"/></div>
                <div className="costs-section__wrap ">
                    <Plate className={'hidden'} items={plateItems}/>
                </div>
            </div>
        </section>
    );
};

export default NoCostSection;