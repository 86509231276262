import React, {FC} from 'react';

interface IScrollUpButton {
    active: boolean;
    onClick?: ()=>void
}

const ScrollUpButton:FC<IScrollUpButton> = ({active, onClick}) => {
    return (<>
            {active &&
            <div className={`scroll-up`} onClick={onClick}>
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="17" cy="17" r="16" stroke="black" strokeWidth="2"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M21.8667 16.195C21.8394 16.1211 21.7885 16.02 21.7535 15.9703C21.6242 15.7869 17.8634 11.5201 17.778 11.46C17.6506 11.3704 17.4579 11.3195 17.312 11.3369C17.0373 11.3697 17.1604 11.2434 14.9743 13.7354C13.3256 15.6148 12.9625 16.0401 12.915 16.1474C12.8337 16.3308 12.821 16.6238 12.8858 16.8199C12.9558 17.0323 13.0732 17.1839 13.2352 17.2716C13.3596 17.3389 13.3918 17.346 13.57 17.346C13.9103 17.346 13.8675 17.3837 15.0797 16.0169C15.654 15.3694 16.2302 14.706 16.282 14.6483C16.3337 14.5906 16.4166 14.4842 16.4994 14.3778L16.6033 14.2477L16.6136 18.3939L16.624 22.7373L16.672 22.8732C16.7391 23.063 16.8946 23.2497 17.0653 23.3456C17.1887 23.415 17.2214 23.423 17.3794 23.4224C17.5256 23.4218 17.576 23.411 17.6734 23.3595C17.8305 23.2762 17.97 23.1248 18.0496 22.951L18.1149 22.8082L18.1253 18.4219L18.1357 14.2477L18.2383 14.366L18.341 14.4849L19.5326 15.8476C20.9078 17.4204 20.8261 17.346 21.1797 17.346C21.364 17.346 21.3996 17.3388 21.5033 17.2807C21.5674 17.2447 21.6635 17.1619 21.717 17.0966C21.8564 16.9264 21.9004 16.7994 21.9091 16.5421C21.9152 16.3592 21.9093 16.3103 21.8667 16.195Z" fill="black"/>
                </svg>
            </div>
            }

        </>

        );
};

export default ScrollUpButton;