import React from 'react';

const PeopleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="63" viewBox="0 0 64 63" fill="none">
            <g clipPath="url(#clip0_58_1989)">
                <path d="M38.5178 14.0905C41.4307 15.9169 43.4733 19.0075 43.8452 22.595C45.0332 23.1501 46.3517 23.4693 47.7496 23.4693C52.853 23.4693 56.9893 19.333 56.9893 14.2304C56.9893 9.12704 52.853 4.99072 47.7496 4.99072C42.695 4.9923 38.5948 9.05471 38.5178 14.0905ZM32.4647 33.0085C37.568 33.0085 41.7043 28.8714 41.7043 23.7689C41.7043 18.6663 37.5672 14.53 32.4647 14.53C27.3621 14.53 23.2234 18.6671 23.2234 23.7696C23.2234 28.8722 27.3621 33.0085 32.4647 33.0085ZM36.384 33.6383H28.5438C22.0205 33.6383 16.7135 38.9461 16.7135 45.4694V55.0574L16.7379 55.2075L17.3983 55.4143C23.6236 57.3594 29.032 58.0081 33.4836 58.0081C42.1784 58.0081 47.2181 55.5291 47.5287 55.3711L48.1459 55.0589H48.2119V45.4694C48.2143 38.9461 42.9073 33.6383 36.384 33.6383ZM51.6705 24.0999H43.8908C43.8067 27.2125 42.478 30.0154 40.3764 32.0313C46.1748 33.7555 50.4173 39.1324 50.4173 45.4835V48.4382C58.0987 48.1567 62.5251 45.9796 62.8168 45.8334L63.434 45.5205H63.5V35.9294C63.5 29.4069 58.193 24.0999 51.6705 24.0999ZM16.252 23.4709C18.0595 23.4709 19.7412 22.9433 21.1659 22.0447C21.6187 19.0908 23.2022 16.5097 25.4641 14.7517C25.4736 14.5787 25.4901 14.4073 25.4901 14.2328C25.4901 9.1294 21.353 4.99308 16.252 4.99308C11.1478 4.99308 7.01228 9.1294 7.01228 14.2328C7.01228 19.3338 11.1478 23.4709 16.252 23.4709ZM24.5498 32.0313C22.4584 30.0256 21.1344 27.2377 21.0377 24.1439C20.7492 24.1227 20.4638 24.0999 20.1697 24.0999H12.3303C5.807 24.0999 0.5 29.4069 0.5 35.9294V45.5189L0.524373 45.6667L1.1848 45.8751C6.17888 47.4342 10.636 48.1528 14.5073 48.3792V45.4835C14.5089 39.1324 18.7498 33.757 24.5498 32.0313Z" fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_58_1989">
                    <rect width="63" height="63" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default PeopleIcon;