import React from 'react';

const TouchIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="63" viewBox="0 0 64 63" fill="none">
            <path d="M22.609 36.0351L20.1628 35.4238L16.2253 34.4395L12.2878 33.4551V25.5801C12.2878 24.4933 11.4058 23.6113 10.3191 23.6113C9.23234 23.6113 8.35034 24.4933 8.35034 25.5801V40.7217C8.35034 38.3395 6.419 36.4082 4.03681 36.4082C3.15776 36.4082 2.44409 37.1219 2.44409 38.0009C2.44409 38.248 2.50217 38.4921 2.61242 38.7136L3.78875 41.0673C4.19923 41.8872 4.41284 42.7919 4.41284 43.7083V48.1183C4.41284 49.459 4.86959 50.7604 5.70631 51.8078L8.35034 55.1113L8.01959 55.6075C6.95154 57.21 6.38159 59.0921 6.38159 61.0176H22.1316C22.1316 59.0921 21.5616 57.21 20.4936 55.6075L20.1628 55.1113L22.8069 51.8078C23.6436 50.7604 24.1003 49.459 24.1003 48.1183V37.9458C24.1003 37.0421 23.4851 36.2546 22.609 36.0351Z" fill="black"/>
            <path d="M9.33472 12.7832H13.2722V14.752H9.33472V12.7832Z" fill="black"/>
            <path d="M6.38159 25.5801C6.38159 23.4085 8.14756 21.6426 10.3191 21.6426C12.4906 21.6426 14.2566 23.4085 14.2566 25.5801V28.5332H19.1785V12.7832H15.241V16.7207H7.36597V12.7832H3.42847V28.5332H6.38159V25.5801Z" fill="black"/>
            <path d="M57.5691 2.93945H52.6472V3.92383C52.6472 5.55198 51.3222 6.87695 49.6941 6.87695H41.8191C40.1909 6.87695 38.866 5.55198 38.866 3.92383V2.93945H33.9441C32.3159 2.93945 30.991 4.26442 30.991 5.89258V49.2051C30.991 50.8332 32.3159 52.1582 33.9441 52.1582H57.5691C59.1972 52.1582 60.5222 50.8332 60.5222 49.2051V5.89258C60.5222 4.26442 59.1972 2.93945 57.5691 2.93945ZM46.741 49.2051H44.7722C43.1411 49.2051 41.8191 47.8831 41.8191 46.252C41.8191 45.7076 42.2591 45.2676 42.8035 45.2676H48.7097C49.2541 45.2676 49.6941 45.7076 49.6941 46.252C49.6941 47.8831 48.3721 49.2051 46.741 49.2051ZM58.5535 40.3447C58.5535 41.4305 57.6705 42.3135 56.5847 42.3135H34.9285C33.8427 42.3135 32.9597 41.4305 32.9597 40.3447V10.8135C32.9597 9.7277 33.8427 8.84472 34.9285 8.84472H56.5847C57.6705 8.84472 58.5535 9.7277 58.5535 10.8135V40.3447Z" fill="black"/>
            <path d="M34.9285 10.8145V40.3457H56.5867L56.5847 10.8145H34.9285ZM45.7566 37.3926C41.4076 37.3926 37.8816 33.8665 37.8816 29.5176C37.8816 25.1686 41.4076 21.6426 45.7566 21.6426C50.1056 21.6426 53.6316 25.1686 53.6316 29.5176C53.6316 33.8665 50.1056 37.3926 45.7566 37.3926ZM54.616 18.6895H36.8972V16.7207H54.616V18.6895ZM54.616 14.752H36.8972V12.7832H54.616V14.752Z" fill="black"/>
            <path d="M41.8191 4.9082H49.6941C50.2375 4.9082 50.6785 4.46622 50.6785 3.92383V2.93945H40.8347V3.92383C40.8347 4.46622 41.2757 4.9082 41.8191 4.9082Z" fill="black"/>
            <path d="M43.7879 31.0789L42.5151 29.8062L41.1232 31.1981L43.7879 33.8628L50.3901 27.2606L48.9982 25.8687L43.7879 31.0789Z" fill="black"/>
        </svg>
    );
};

export default TouchIcon;