import React, {useRef, useEffect, useState} from 'react';
import SmoothScrollbar from 'smooth-scrollbar';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useDispatchEx} from "./hooks/redux";
import { changeTimeLine } from './redux/slices/modalsSlice';

export const timeline = gsap.timeline({
    repeat:0
});
gsap.registerPlugin(ScrollTrigger);

const Scrollbar = ({ children }: { children:React.ReactNode }) => {
    const [progress,setProgress] = useState(0);
    const containerRef = useRef<any>(null);
    const scrollbarRef = useRef<any>(null);
    const timerRef = useRef<any>(new Date().getTime());
   // const { scrollContainerRef, scrollToBlock } = useSmoothScroll();
    const dispatch = useDispatchEx();
    useEffect(() => {
        if (containerRef.current) {
            const scrollbar = SmoothScrollbar.init(containerRef.current, {
                damping:0.07
                // Add your Smooth Scrollbar options here
            });

            scrollbar.addListener(() => {
                if(timerRef.current+900 < new Date().getTime() || timerRef.current+100 < new Date().getTime() && scrollbar.scrollTop <= 150){
                    timerRef.current = new Date().getTime()
                    dispatch(changeTimeLine(scrollbar.scrollTop ))
                    console.log('Update')
                }
                setProgress(scrollbar.scrollTop / scrollbar.limit.y);
              /*  dispatch(changeTimeLine(scrollbar.scrollTop ))
                setProgress(scrollbar.scrollTop / scrollbar.limit.y);*/
               // timeline.progress(scrollbar.scrollTop / scrollbar.limit.y);/ scrollbar.limit.y

            });
            scrollbarRef.current = scrollbar;
        }

        return () => {
            if (scrollbarRef.current) {
               // scrollbarRef.current.removeAllListeners();
                scrollbarRef.current.destroy();
                scrollbarRef.current = null;
            }
        };
    }, []);

    return (
        <>
            <div className="progress-bar" style={{width:progress*100+"%"}}></div>
            <div ref={containerRef} className="scroll-container">
                {children}
            </div>

        </>
    );
};

export default Scrollbar;