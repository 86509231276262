import React, {FC} from 'react';

export interface IPlateItem {
    className?:string;
    header: React.ReactNode;
    count: React.ReactNode;
    desc: React.ReactNode;
}

const PlateItem:FC<IPlateItem> = ({className,header,count,desc}) => {
    return (
        <div className={`plate__item ${className ? className:''}`}>
            <div className="plate__header">{header}</div>
            <div className="plate__count">{count}</div>
            <div className="plate__desc" >{desc}</div>
        </div>
    );
};

export default PlateItem;