import React, {FC} from 'react';
import logo from "../assets/img/logo.svg";

interface ILogo {
    className?: string;
}

const Logo:FC<ILogo> = ({className}) => {
    return (
        <div className={`logo ${className ? className : ''}`}>
            <img src={logo} alt=""/>
        </div>
    );
};

export default Logo;