import React, {FC, useEffect, useRef, useState} from 'react';
import onlineImg from '../../assets/img/v3/online-section.png'
import onlineMob from '../../assets/img/online_orange.svg'
import {Trans, useTranslation} from "react-i18next";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {useOnDisplay} from "../../hooks/useOnDisplay";
gsap.registerPlugin(ScrollTrigger);
const OnlineSection:FC = () => {
    const {isOnDisplay, ref} = useOnDisplay();
    const [isShowed,setIsShowed] = useState(false);

    let timeline = gsap.timeline({repeat:0});
    useEffect(()=>{
        if(isOnDisplay && !isShowed){
            setIsShowed(true)
            const UpStart = {y:50,opacity:0,duration: 0.5, stagger: 0.5};
            const InFinish = {y:0,opacity:1,duration: 0.5, stagger: 0.5};
            timeline.fromTo(".online-section__description", UpStart, InFinish, 0.5)
                .fromTo(".online-section__header", UpStart, InFinish, 0.9)
                .fromTo(".online-section__list", UpStart, InFinish, 1.3)
                .fromTo(".online-section__content", UpStart, InFinish, 1.7)
                .fromTo(".online-section__img", UpStart, InFinish, 2.1)
            ;
        }
    },[isOnDisplay]);
    const { t } = useTranslation();
    return (
        <section className={'online-section'} ref={ref}>
            <div className="container">
                <div className="row ">
                    <div className="col-xl-6 order-xl-1 order-2">
                        <div className="online-section__img hidden">
                            <picture>
                                <source media={"(max-width:992px)"} srcSet={onlineImg}/>
                                <img src={onlineImg} alt=""/>
                            </picture>
                        </div>
                    </div>
                    <div className="col-xl-6 order-xl-2 order-1">
                        <div className="online-section__wrap">
                            <div className="online-section__description hidden">
                                <p>Get your own online marketing tools and points of sales instantly, without investments and developers costs</p>
                                <p>Manage full types of online orders and bookings, without waiters and additional staff help</p>
                                <p>Accept online payments and tips without bank terminal and additional equipment, just with your phone</p>

                            </div>
                            <div className="online-section__header hidden">
                                <div className="online-section__word ">
                                    <picture>
                                        <source media={"(max-width:992px)"} srcSet={onlineMob}/>
                                        <img src={onlineMob} alt=""/>
                                    </picture>
                                </div>
                                <div className="online-section__list h2-light hidden">
                                    <Trans i18nKey="online-section.list"/>
                                </div>
                            </div>
                            <div className="online-section__content hidden">
                                <Trans i18nKey="online-section.content"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OnlineSection;